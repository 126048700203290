<template>
  <transition>
    <TheLoader v-if="loader" />
  </transition>

  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />

    <transition name="layout-sidebar">
      <div
        :class="sidebarClass"
        @click="onSidebarClick"
        v-show="isSidebarVisible()"
      >
        <div class="layout-logo">
          <router-link to="/">
            <img alt="Logo" src="@/assets/logo.svg" style="width: 50%" />
          </router-link>
        </div>

        <AppProfile />
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" class="mt-4" />
      </div>
    </transition>

    <div class="layout-main">
      <router-view />
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import AppProfile from "./AppProfile.vue";
import { LoaderGetters } from "@/store/loader";
import TheLoader from "@/components/app/TheLoader";
import {UserGetters} from "@/store/user/getters";
import {UserRoles} from "@/store/user/state";
import {AuthGetters} from "@/store/auth";

export default {
  components: {
    AppTopBar,
    AppMenu,
    AppProfile,
    AppFooter,
    TheLoader,
  },
  data() {
    return {
      layoutMode: "static",
      layoutColorMode: "light",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        { label: "Главная страница", icon: "pi pi-fw pi-home", to: "/" },
        { label: "Пользователи", icon: "pi pi-fw pi-users", to: "/user" },
        { label: "Профиль", icon: "pi pi-fw pi-user", to: "/profile" },
        { label: "Объекты", icon: "pi pi-fw pi-list", to: "/facility" },
        {
          label: "Транзакций",
          icon: "pi pi-fw pi-dollar",
          to: "/payments",
        },
        {
          label: "Настройки",
          icon: "pi pi-fw pi-cog",
          visible: () => this.role === UserRoles.ADMIN,
          items: [
            {
              label: "Регион",
              icon: "pi pi-fw pi-home",
              to: "/settings/region",
            },
            {
              label: "Город",
              icon: "pi pi-fw pi-home",
              to: "/settings/city",
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    sidebarClass() {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },
      ];
    },
    loader() {
      return this.$store.getters[LoaderGetters.GET_LOADER];
    },
    role() {
      return this.$store.getters[AuthGetters.GET_ROLE];
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
};
</script>

<style lang="scss">
@import "@/App.scss";
@import "~boxicons/css/boxicons.min.css";
</style>
