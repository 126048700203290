import { createStore, createLogger } from "vuex";
import createPersistedState from "vuex-persistedstate";

import { auth } from "@/store/auth";
import { user } from "@/store/user";
import { loader } from "@/store/loader";
import { facility } from "@/store/facility";
import { city } from "@/store/city";
import { region } from "@/store/region";
import { payment } from "@/store/payment";
import { IRootState } from "@/store/types";

const plugins = [];
if (process.env.NODE_ENV === "development") {
  plugins.push(createLogger());
}

export const store = createStore<IRootState>({
  state: {
    version: 0,
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    loader,
    facility,
    city,
    region,
    payment,
  },
  plugins: [createPersistedState()],
});
