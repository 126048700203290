import { IDataProvider } from "@/store/types";

export interface IFacility {
  id?: number;
  name: string;
  type: number;
  cityId: number;
  userId: number;
  expireDate: Date;
}

export interface IFacilityState {
  facility: IFacility | null;
  facilities: IFacility[];
  dataProvider: IDataProvider<IFacility>;
}

export enum FacilityTypes {
  RESTAURANT = 0,
  CAFE = 1,
  BAR = 2,
  COFFEE = 3,
  FAST_FOOD = 4,
  BAKERY = 5,
  HOOKAH = 6,
  FOOD_TRUCK = 7,
  CONFECTIONERY = 8,
  PIZZERIA = 9,
  CANTEEN = 10,
  SUSHI = 11,
  FRANCHISE = 12,
}

export const facilityTypes = [
  {
    value: FacilityTypes.RESTAURANT,
    label: "Ресторан",
  },
  {
    value: FacilityTypes.CAFE,
    label: "Кафе",
  },
  {
    value: FacilityTypes.BAR,
    label: "Бар",
  },
  {
    value: FacilityTypes.COFFEE,
    label: "Кофейня",
  },
  {
    value: FacilityTypes.FAST_FOOD,
    label: "Фастфуд",
  },
  {
    value: FacilityTypes.BAKERY,
    label: "Пекарня",
  },
  {
    value: FacilityTypes.HOOKAH,
    label: "Кальянная",
  },
  {
    value: FacilityTypes.FOOD_TRUCK,
    label: "Фудтрак",
  },
  {
    value: FacilityTypes.CONFECTIONERY,
    label: "Кондитерская",
  },
  {
    value: FacilityTypes.PIZZERIA,
    label: "Пиццерия",
  },
  {
    value: FacilityTypes.CANTEEN,
    label: "Столовая",
  },
  {
    value: FacilityTypes.SUSHI,
    label: "Суши",
  },
  {
    value: FacilityTypes.FRANCHISE,
    label: "Франшиза, сеть заведений",
  },
];

export const state: IFacilityState = {
  facility: null,
  facilities: [],
  dataProvider: {
    currentPage: 0,
    records: [],
    totalItems: 0,
    totalPages: 0,
  },
};
