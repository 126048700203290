<template>
  <div class="layout-profile">
    <div>
      <img src="@/assets/profile.png" alt="profile" />
    </div>
    <button class="p-link layout-profile-link">
      <span class="username">{{ fullName }}</span>
      <i class="pi pi-fw pi-cog"></i>
    </button>
  </div>
</template>

<script>
import {AuthGetters} from "@/store/auth";

export default {
  data() {
    return {
      fullName: null,
    };
  },
  mounted() {
    this.fullName = this.$store.getters[AuthGetters.GET_FULL_NAME];
  },
};
</script>

<style scoped></style>
