import { ActionTree } from "vuex";

import { ICityState, ICity } from "@/store/city/state";
import { IRootState } from "@/store/types";
import { CityMutations } from "@/store/city/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum CityActions {
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_CITY",
  CREATE_ITEM = "CREAT_ITEM_CITY",
  FETCH_ONE = "FETCH_ONE_CITY",
  FETCH_ALL = "FETCH_ALL_CITY",
  UPDATE_ITEM = "UPDATE_ITEM_CITY",
  DELETE = "DELETE_CITY",
}

const routeName = `city`;

export const actions: ActionTree<ICityState, IRootState> = {
  [CityActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(CityMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CityActions.FETCH_ALL]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/index`, {
          params: params
        })
        .then((response) => {
          commit(CityMutations.SET_ALL, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CityActions.FETCH_ONE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/${id}`)
        .then((response) => {
          commit(CityMutations.SET_ONE, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CityActions.CREATE_ITEM]({ commit, dispatch }, payload: ICity) {
    return new Promise((resolve, reject) => {
      baseApi
        .post(`${getUrlAddress(routeName)}/create`, payload)
        .then(() => {
          commit(CityMutations.SET_ONE, payload);
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [CityActions.UPDATE_ITEM]({ dispatch }, payload: ICity) {
    return new Promise((resolve, reject) => {
      baseApi
        .put(`${getUrlAddress(routeName)}/update?id=${payload.id}`, {
          data: payload,
        })
        .then(() => {
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [CityActions.DELETE]({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      baseApi
        .delete(`${getUrlAddress(routeName)}/delete?id=${payload.id}`)
        .then(() => {
          dispatch(CityActions.FETCH_LAZY_DATA, payload.lazyParams);
          resolve(true);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};
