import { ActionTree } from "vuex";
import axios from "axios";

import { IPayment, IPaymentState } from "@/store/payment/state";
import { IRootState } from "@/store/types";
import { PaymentMutations } from "@/store/payment/mutations";
import { baseApi, getUrlAddress } from "@/store/config";

export enum PaymentActions {
  FETCH_ALL = "FETCH_ALL_PAYMENT",
  UPDATE_ITEM = "UPDATE_ITEM_PAYMENT",
  DELETE_ITEM = "DELETE_ITEM_PAYMENT",
  SET_ITEM = "SET_ITEM_PAYMENT",
  FETCH_ONE = "FETCH_ONE_PAYMENT",
  FETCH_LAZY_DATA = "FETCH_LAZY_DATA_PAYMENT",
}

const routeName = `payment`;

export const actions: ActionTree<IPaymentState, IRootState> = {
  [PaymentActions.FETCH_LAZY_DATA]({ commit }, params) {
    return new Promise((resolve, reject) => {
      baseApi
        .get(`${getUrlAddress(routeName)}/data-provider`, {
          params: params,
        })
        .then((response) => {
          commit(PaymentMutations.SET_LAZY_DATA, response.data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
