import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Sales from "../views/statistics/Sales.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Home from "@/views/Home.vue";

import { store } from "@/store";
import { UserGetters } from "@/store/user/getters";
import { UserRoles } from "@/store/user/state";
import { AuthGetters } from "@/store/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Home,
    meta: { auth: true },
  },

  {
    path: "/login",
    name: "Login",
    meta: { layout: "auth", auth: false },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { auth: true },
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/payments",
    name: "Payments",
    meta: { auth: true },
    component: () => import("../views/Payments.vue"),
  },
  {
    path: "/facility",
    name: "Facility",
    meta: { auth: true },
    component: () => import("../views/facility/Index.vue"),
  },
  {
    path: "/facility/form",
    name: "FacilityForm",
    meta: { auth: true },
    component: () => import("../views/facility/Form.vue"),
  },
  {
    path: "/settings/region",
    name: "SettingsRegion",
    meta: { auth: true },
    component: () => import("../views/settings/region/Index.vue"),
  },
  {
    path: "/settings/region/form",
    name: "SettingsRegionForm",
    meta: { auth: true },
    component: () => import("../views/settings/region/Form.vue"),
  },
  {
    path: "/settings/city",
    name: "SettingsCity",
    meta: { auth: true },
    component: () => import("../views/settings/city/Index.vue"),
  },
  {
    path: "/settings/city/form",
    name: "SettingsCityForm",
    meta: { auth: true },
    component: () => import("../views/settings/city/Form.vue"),
  },
  {
    path: "/user",
    name: "User",
    meta: { auth: true },
    component: () => import("../views/user/Index.vue"),
  },
  {
    path: "/user/form",
    name: "UserForm",
    meta: { auth: true },
    component: () => import("../views/user/Form.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    meta: { layout: "empty", auth: false },
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const role = store.getters[AuthGetters.GET_ROLE];

  if (!to.meta.auth) {
    next();
    return;
  }

  if (to.meta.auth) {
    if (
      store.getters[AuthGetters.GET_IS_AUTHENTICATED] &&
      role !== UserRoles.WAITER &&
      role !== UserRoles.DIRECTOR
    ) {
      next();
    } else {
      next({ name: "Login" });
    }
  }
});

export default router;
