import { createApp, reactive } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";

import PrimeVue from "primevue/config";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Ripple from "primevue/ripple";
import CodeHighlight from "./AppCodeHighlight";
import Tooltip from "primevue/tooltip";
import Textarea from "primevue/textarea";
import InputMask from "primevue/inputmask";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import ProgressSpinner from "primevue/progressspinner";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Toolbar from "primevue/toolbar";
import { setupAxiosInterceptors } from "@/store/config";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.min.css";
import "primeicons/primeicons.css";
import "prismjs/themes/prism-coy.css";
import "./assets/layout/layout.scss";
import "./assets/layout/flags/flags.css";

process.env.TZ = "Asia/Almaty";

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0);
  next();
});

setupAxiosInterceptors(() => console.log("Un"));

const app = createApp(App);

app.config.globalProperties.$appState = reactive({ inputStyle: "outlined" });

const locale = {
  startsWith: "Начинается с",
  contains: "Содержит",
  notContains: "Не содержит",
  endsWith: "Заканчивается на",
  equals: "Равно",
  notEquals: "Не равно",
  noFilter: "Без фильтра",
  lt: "Меньше",
  lte: "Меньше или равно",
  gt: "Больше",
  gte: "Больше или равно",
  dateIs: "Этот дата",
  dateIsNot: "Не этот дата",
  dateBefore: "Дата до",
  dateAfter: "Дата после",
  clear: "Очистить",
  apply: "Применить",
  matchAll: "Match All",
  matchAny: "Match Any",
  addRule: "Add Rule",
  removeRule: "Remove Rule",
  accept: "Да",
  reject: "Нет",
  choose: "Выбрать",
  upload: "Загрузить",
  cancel: "Отменить",
  dayNames: [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ],
  dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  monthNames: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  today: "Сегодня",
  weekHeader: "Wk",
  firstDayOfWeek: 0,
  dateFormat: "mm/dd/yy",
  weak: "Weak",
  medium: "Medium",
  strong: "Strong",
  passwordPrompt: "Enter a password",
  emptyFilterMessage: "Ничего не найдено",
  emptyMessage: "Нет доступных вариантов",
};

app.use(store);
app.use(router);
app.use(PrimeVue, { ripple: true, locale });
app.use(ToastService);
app.use(ConfirmationService);

app.directive("tooltip", Tooltip);
app.directive("ripple", Ripple);
app.directive("code", CodeHighlight);

app.component("Button", Button);
app.component("InputText", InputText);
app.component("InputNumber", InputNumber);
app.component("Toast", Toast);
app.component("Dropdown", Dropdown);
app.component("Textarea", Textarea);
app.component("InputMask", InputMask);
app.component("ConfirmDialog", ConfirmDialog);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Calendar", Calendar);
app.component("Checkbox", Checkbox);
app.component("Toolbar", Toolbar);

app.mount("#app");
